.card {
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
  }
  
  .card-content {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .pricing-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pricing-list li {
    margin-bottom: 10px;
  }
  
  
  .cd-word {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease;
  }
  
  .cd-word.is-visible {
    opacity: 1;
    visibility: visible;
  }



  /* fun facts */
  
/* .fun-facts {
	margin-top: 140px;	
  background-image: linear-gradient(rgba(17, 17, 17, 0.5), rgba(80, 78, 74, 0.5)), url('../public/images/luca.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 140px 0px;
	color: #fff;
} */

.fun-facts span {
	text-transform: uppercase;
	font-size: 15px;
	color: #fff;
	letter-spacing: 1px;
	margin-bottom: 10px;
	display: block;
}

.fun-facts h2 {
	font-size: 36px;
	font-weight: 600;
	margin-bottom: 35px;
}

.fun-facts em {
	font-style: normal;
	color: #FFB527;
}

.fun-facts p {
	color: #fff;
	margin-bottom: 40px;
}

.fun-facts .left-content {
	margin-right: 45px;
}

.count-area-content {
	text-align: center;
	background-color: rgba(250,250,250,0.1);
	padding: 25px 30px 35px 30px;
	margin: 15px 0px;
}

.count-digit {
    margin: 5px 0px;
    color: #FFB527;
    font-weight: 700;
    font-size: 36px;
}
.count-title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.5px;
}





/* about section */
.about-section {
  position: relative; /* Set position to enable overlay */

}

.about-section::after {
  content: ""; /* Create a pseudo-element */
  position: absolute; /* Position the pseudo-element */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Set the overlay color */
  opacity: 0.7; /* Adjust the opacity as needed */
  z-index: -1; /* Place the pseudo-element below the section content */
}







  
  
  
 